[class*=containerElement] {
    display: flex;
    margin: 0.2rem;
    flex-wrap: wrap;
}

[class*=containerElement] > span {
   min-width: 1.5rem;
   max-width: 1.5rem;
   margin-bottom: 0;
   text-transform: uppercase;
}

.containerElement_title {
    font-size: 1rem;
    font-weight: 400;
    align-self: flex-end;
    padding-right: 5rem;
    padding-top: 5rem;
}   

.containerElement_title > span {
   min-width: 16px;
   max-width: 16px;
}

@media screen and (max-width: 640px) {
    [class*=containerElement] > span {
        min-width: 1rem;
        max-width: 1rem;
    }
    .containerElement_title {
        font-size: 0.8rem;
    }   
}