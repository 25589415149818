.App {
  text-align: center;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: #282c34;
  min-height: 100vh;
  font-size: calc(0.625rem + 2vmin);
  color: white;
  padding: 0 5rem;
  font-weight: 700;
}

@media screen and (max-width: 640px) {
  .App-header {
    padding: 0 2rem;
    font-size: calc(0.625rem + 1.5vmin);
  }
}

.App-header > section {
  display: flex;
  flex-direction: column;
  min-height: 20rem;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
